import React, { useState, useContext, useEffect, useRef } from "react";
import AuthContext from "../login/components/AuthContext";
import { Col, Container, Form, Row } from "react-bootstrap";
import style from "./styles/NoLineDataLogin.module.css";
import loginStyle from "./styles/LoginIndex.module.css";
import KidProLoginMark from "./imgs/KidProLoginMark.svg";
import LINEIcon from "./imgs/LINEIcon.svg";
import CountryData from "./coutryCode.json";
import popupStyle from "../../Components/styles/popup.module.css";
import Modal from "react-bootstrap/Modal";
//引入loading 轉圈圈元件
import LoadingSpinner from "../../Components/Spinner/Spinner";
//liff SDK
import liff from "@line/liff";
import nodeRSA from "node-rsa"; // RSA加密元件

import PopupBanner from "../login/imgs/PopupBanner.png";

function NoLineDataLogin(props) {
  const { setSheet, Sheet, setNOuaabindLine } = props;
  const {
    authorized,
    email,
    setEmail,
    auth,
    setAuth,
    userNormalLoggedIn,
    setUserNormalLoggedIn,
  } = useContext(AuthContext);

  //設定登入資料
  const [myform, setMyform] = useState({});
  // 設定進入電子郵件表單or 電話登入表單 true-->手機登入表單
  const [LoginWay, setLoginWay] = useState(false);
  const [RegxpError, setRegxpError] = useState("");
  // 設定loading 轉圈圈顯示與否
  const [isLoading, setIsLoading] = useState(false);
  // 設定是否綁定手機/Email
  const [lineBind, setLineBind] = useState(true);
  // 設定是否前往註冊帳號
  const [registerForm, setRegisterForm] = useState(false);

  // 設定radio 選取的值
  const [radioValue, setradioValue] = useState({
    member: "KidProMember",
  });

  const onChangeRadioValue = (e) => {
    const val = e.target.value;
    setradioValue({ ...radioValue, member: val });
  };

  // 自動填入參照欄位
  const autoEmail = useRef(null);
  const autoPhone = useRef(null);

  // 簡訊驗證碼參照欄位
  const smscode = useRef(null);
  // 發送驗證碼按鈕文字
  const sendSMSText = useRef(null);

  const backendURL = process.env.REACT_APP_BACKENDURL; // 首頁URL
  const apiURL = process.env.REACT_APP_APIURL; // API URL

  //設定國碼資料類型
  let coutryCodeArray = [];
  coutryCodeArray = Object.entries(CountryData.id_to_countrycode);

  //加入預設值+886
  if (!myform.countryCode) {
    setMyform({ ...myform, countryCode: "+886" });
  }

  // 記錄表單欄位輸入值
  const changeFields = (event) => {
    const id = event.target.id;
    const val = event.target.value;
    // // console.log({ id, val });
    setMyform({ ...myform, [id]: val });
    setRegxpError("");
  };

  // 以Email登入
  const toEmailLogin = () => {
    setLoginWay(false);
    setRegxpError("");
  };

  // 以手機登入
  const toPhoneLogin = () => {
    setLoginWay(true);
    setRegxpError("");
  };

  /* 登入手機驗證 */
  const verifyPhone = () => {
    // 設定手機驗證格式
    let phoneRegxp = /^[1-9]{1}[0-9]{4,19}$/;
    let phoneRegxpWith0 = /^[0]+[0-9]{4,19}$/;
    // 手機驗證結果
    let phoneRegxpResult = phoneRegxp.test(myform.phone);
    let phoneRegxpResultWith0 = phoneRegxpWith0.test(myform.phone);
    // 若表單有自動填入值，則使用自動填入值驗證
    if (!!autoPhone.current) {
      phoneRegxpResult = phoneRegxp.test(autoPhone.current.value);
      phoneRegxpResultWith0 = phoneRegxpWith0.test(autoPhone.current.value);
    }
    // 驗證失敗顯示格式錯誤訊息
    if (
      LoginWay === true &&
      phoneRegxpResult === false &&
      phoneRegxpResultWith0 === false
    ) {
      return false;
    }
    return true;
  };

  /* 登入Email驗證 */
  const verifyEmail = () => {
    // 設定Email驗證格式
    let emailRegxp =
      /^\w+((-\w+)|(\.\w+))*\@[A-Za-z0-9]+((\.|-)[A-Za-z0-9]+)*\.[A-Za-z]+$/;
    // Email驗證結果
    let emailRegxpResult = emailRegxp.test(myform.email);
    // 若表單有自動填入值，則使用自動填入值驗證
    if (!!autoEmail.current) {
      emailRegxpResult = emailRegxp.test(autoEmail.current.value);
    }
    // 驗證失敗顯示格式錯誤訊息
    if (LoginWay === false && emailRegxpResult === false) {
      setIsLoading(false);
      setRegxpError("請填寫正確Email資訊");
      return false;
    }
    return true;
  };

  /* 使用者點選登入(未加密) */
  // const whenSubmit = (event) => {
  //   event.preventDefault();
  //   // if(radioValue.member==='KidProMember'){
  //     //選第一個radio button-->進入登入流程
  //     // // console.log('選我已經是KidPro會員(登入即完成設定)')
  //     //刪除前段錯誤訊息
  //     setRegxpError('');
  //     // // console.log('myform',myform)

  //     //密碼比對
  //     if(!myform.password){
  //       setRegxpError('請填寫輸入密碼欄位')
  //       return;
  //     }

  //     /* 檢查使用者是否參加過活動或已登記活動表單 */
  //     const checkActivityAvailable = () => {
  //       const loginToken = window.localStorage.getItem('loginToken') ? window.localStorage.getItem('loginToken') : "";
  //       const BearerToken = 'Bearer ' + loginToken;
  //       // POST送出手機資料，驗證是否成功
  //       fetch('https://' + apiURL + '/api/marketing/event_user/share_yoga', {
  //         method: 'GET',
  //         headers: {
  //           'Content-Type': 'application/json',
  //           'Access-Control-Allow-Origin': '*',
  //           'Authorization': BearerToken
  //         },
  //       })
  //       .then((r) => r.json())
  //       .then((result) => {
  //         //// // // console.log('result', result);
  //         if ( result.status === 200 && !! result.data ) {
  //           setRegxpError('您已經登記過此活動囉！');
  //           alert('您已經登記過此活動囉！');
  //           window.location.assign( window.location.protocol + "//" + window.location.host + "/CountingPage" );
  //         } else if ( result.status === 400 && result.errorCode === 1001 ) {
  //           setRegxpError('您已經參加過上次活動囉！');
  //           alert('您已經參加過上次活動囉！');
  //           window.location.assign( window.location.protocol + "//" + window.location.host + "/Forbidden" );
  //         } else if ( result.status === 404 && result.errorCode === 1002 ) {
  //           window.location.assign( window.location.protocol + "//" + window.location.host + "/SubmitPage" );
  //         } else {
  //           alert('伺服器錯誤，請稍後再試！');
  //           setRegxpError('伺服器錯誤，請稍後再試！');
  //         }
  //       })
  //       .catch((error) => {
  //         alert('伺服器錯誤，請稍後再試！');
  //         setRegxpError('伺服器錯誤，請稍後再試！');
  //       }); // END Fetch
  //     }

  //     let myformTobodyformat = {};
  //     let santiPhone = '';
  //     //判斷登入方式 手機or email
  //     if(LoginWay === true) { // 手機登入
  //       // 若未通過驗證，顯示錯誤訊息並結束函式
  //       if( ! verifyPhone() ) {
  //         setIsLoading(false);
  //         setRegxpError('請填寫正確手機資訊');
  //         return;
  //       }

  //       //若首號包含0，把0去除
  //       let phoneRegxpWith0 = /^[0]+[0-9]{4,19}$/;
  //       let phoneRegxpResultWith0 = phoneRegxpWith0.test( autoPhone.current.value );
  //       if( phoneRegxpResultWith0 === true ) {
  //         santiPhone = myform.phone.replace( /^([0]+)([0-9]{4,19})$/, '$2' );
  //         // // // console.log('santiPhone',santiPhone);
  //       }

  //       // let phone = myform.countryCode + myform.phone;
  //       let phone = myform.countryCode + santiPhone;

  //       // 若自動填入，則phone為自動填入值
  //       if( !! autoPhone.current ) {
  //         //若首號包含0，把0去除
  //         if( phoneRegxpResultWith0 === true ) {
  //           santiPhone = autoPhone.current.value.replace( /^([0]+)([0-9]{4,19})$/, '$2' );
  //         }
  //         phone = myform.countryCode + santiPhone;
  //       }

  //       let password = myform.password;
  //       let redirectUrl = window.location.href + '/';
  //       // let redirectUrl = 'https://' + apiURL + '';
  //       // let redirectUrl = 'https://localhost:3000/SubmitPage';
  //       myformTobodyformat={
  //         phone,password,redirectUrl
  //       }
  //       // // console.log('phoneloginbodPhone',myformTobodyformat);
  //     } else {// email登入
  //       // 若未通過驗證，顯示錯誤訊息並結束函式
  //       if( ! verifyEmail() ) {
  //         setRegxpError('請填寫正確Email資訊');
  //         return;
  //       }
  //       let email = myform.email;
  //       // 若自動填入，則email為自動填入值
  //       if( !! autoEmail.current ) {
  //         email = autoEmail.current.value;
  //       }

  //       let password = myform.password;
  //       let redirectUrl = window.location.href + '/';
  //       // // // console.log('redirectUrl', redirectUrl);
  //       // let redirectUrl = 'https://' + apiURL + '';
  //       // let redirectUrl = 'https://localhost:3000/SubmitPage';
  //       myformTobodyformat = {
  //       email,password,redirectUrl
  //     }
  //     // // console.log('phoneloginbodyEmail',myformTobodyformat);
  //   }

  //   // 串接KidPro官網登入UAA
  //   setIsLoading(true);
  //   fetch('https://uaa.kid-pro.com/api/uaa/login', {
  //     method: 'POST',
  //     body: JSON.stringify(myformTobodyformat),
  //     headers: {
  //       'Content-Type': 'application/json',
  //     }
  //   })
  //   .then((r) => r.json())
  //   .then((result) => {
  //     // // console.log('result bind', result);
  //     if(result.statusCode === 200) {
  //       window.localStorage.setItem( 'loginToken', result.data.loginToken );
  //       window.location.assign( window.location.protocol + "//" + window.location.host + "/CheckActivity?redir=kidpro" );
  //       let line_bindData = {};
  //       let redirectUrl = window.location.href;
  //       const accessToken = liff.getAccessToken();
  //       line_bindData= {
  //         email: myformTobodyformat.email,
  //         redirectUrl:redirectUrl,
  //         accessToken:accessToken,
  //       };
  //       // // console.log('登入成功，line_bindData',line_bindData);

  //       const loginToken = window.localStorage.getItem('loginToken');
  //       const BearerToken = 'Bearer ' + loginToken;
  //       fetch('https://uaa.kid-pro.com/api/uaa/line_bind', {
  //         method: 'POST',
  //         body: JSON.stringify(line_bindData),
  //         headers: {
  //           'Content-Type': 'application/json',
  //           'Authorization': BearerToken
  //         },
  //       })
  //       .then((r) => r.json())
  //       .then((result) => {
  //         // // console.log('result bind', result);
  //         if(result.status === 200){
  //           // // console.log('成功 200');
  //           setIsLoading(false);
  //           // 登入成功檢查使用者活動紀錄再跳轉
  //           checkActivityAvailable();
  //           // 登入成功跳轉
  //           // // // console.log( 'result.data.url', result.data.url );
  //           // window.location.assign(result.data.url);
  //         }else if(result.status === 400){
  //           setIsLoading(false);
  //           //// // console.log('失敗，錯誤代碼 400')
  //         }else if(result.status === 403){
  //           setIsLoading(false);
  //           //// // console.log('失敗，錯誤代碼 403');
  //         }else{
  //           // // console.log('失敗。');
  //         }
  //       })
  //       .catch((error) => {
  //         setIsLoading(false);
  //         setRegxpError('伺服器錯誤，請稍後再試！');
  //       });
  //     } else if(result.statusCode === 400) {
  //       setIsLoading(false);
  //       setRegxpError('登入失敗，錯誤代碼 400');
  //     } else if(result.statusCode === 403) {
  //       setIsLoading(false);
  //       setRegxpError('帳號或密碼錯誤，錯誤代碼 403');
  //     } else {
  //       setIsLoading(false);
  //       setRegxpError('登入失敗，輸入的手機或Email不存在！');
  //     }
  //   })
  //   .catch((error) => {
  //     setIsLoading(false);
  //     setRegxpError('伺服器錯誤，請稍後再試！');
  //   });

  //   // }else{
  //   //   //選第二個radio button 選我還不是KidPro會員，我要成為新會員
  //   //   // handleshow();
  //   //   setSheet('registerPhone');
  //   // }

  // };   /* END Function 使用者點選登入 */

  /* 加密資料 */
  const cipherData = (data, pkey) => {
    let encryptData = null;
    const cipher = new nodeRSA({ b: 512 }); // 加密物件
    cipher.importKey(pkey, "pkcs1-public"); // 公鑰放入加密物件
    encryptData = cipher.encrypt(JSON.stringify(data), "base64");
    if (!!encryptData) {
      return encryptData;
    }
    return false;
  };

  /* 解密資料 */
  const decipherData = (data) => {
    let tder = null;
    let rtder = null;
    tder = window.localStorage.getItem("tder");
    rtder =
      "-----BEGIN RSA PRIVATE KEY-----" +
      tder +
      "-----END RSA PRIVATE KEY-----"; // 解密密碼金鑰
    const decipher = new nodeRSA(rtder, "pkcs1"); // 金鑰放入解密物件
    const decryptData = JSON.parse(decipher.decrypt(data, "utf8")); // 進行解密
    // // console.log( "decryptData: ", decryptData );
    const returnData = { skey: rtder, decData: decryptData };
    if (!!tder || !!rtder) {
      return returnData;
    }
    return false;
  };

  /* 使用者點選登入(加密登入並進行LINE綁定) */
  const whenSubmit = (event) => {
    event.preventDefault();
    // console.log('whenSubmit');
    //刪除前段錯誤訊息
    setRegxpError("");
    setIsLoading(true);

    let myformTobodyformat = {};
    let santiPhone = "";
    // 判斷登入方式 手機 or email
    if (LoginWay === true) {
      // 手機登入
      // 若未通過驗證，顯示錯誤訊息並結束函式
      if (!verifyPhone()) {
        setIsLoading(false);
        setRegxpError("請填寫正確手機資訊");
        return;
      }
      // 若首號包含0，把0去除
      let phone = myform.countryCode + myform.phone; // 傳入登入API的手機號碼，預設是即時輸入值
      // // // console.log('phone0',phone);
      let phoneRegxpWith0 = /^[0]+[0-9]{4,19}$/; // 包含首位0的手機正規表達式
      let phoneRegxpResultWith0 = phoneRegxpWith0.test(myform.phone); // 即時輸入號碼是否包含0
      // // // console.log('myform.phone',myform.phone);
      // // // console.log('phoneRegxpResultWith0-1',phoneRegxpResultWith0);
      if (phoneRegxpResultWith0 === true) {
        santiPhone = myform.phone.replace(/^([0]+)([0-9]{4,19})$/, "$2"); // 銷去首位0
        // // // console.log('santiPhone',santiPhone);
        phone = myform.countryCode + santiPhone; // 已淨化的即時輸入手機號碼
        // // // console.log('phone1',phone);
      }
      // // // console.log('autoPhone.current',autoPhone.current);
      // 若自動填入，則phone為自動填入值
      if (!!autoPhone.current) {
        // 檢查自動填入是否存在
        phone = myform.countryCode + autoPhone.current.value; // 傳入登入API的手機號碼，若自動填入存在，則傳自動填入值
        // // // console.log('phone2',phone);
        phoneRegxpResultWith0 = phoneRegxpWith0.test(autoPhone.current.value); // 自動填入號碼是否包含0
        // // // console.log('phoneRegxpResultWith0-2',phoneRegxpResultWith0);
        // 若首號包含0，把0去除
        if (phoneRegxpResultWith0 === true) {
          santiPhone = autoPhone.current.value.replace(
            /^([0]+)([0-9]{4,19})$/,
            "$2"
          ); // 銷去首位0
          // // // console.log('santiPhone',santiPhone);
          phone = myform.countryCode + santiPhone; // 已淨化的自動填入手機號碼
          // // // console.log('phone3',phone);
        }
      }
      // // // console.log('phone4',phone);
      let password = myform.password;
      let redirectUrl = window.location.href + "SubmitPage";
      // let redirectUrl = 'https://' + apiURL + '';
      // let redirectUrl = 'https://localhost:3000/SubmitPage';
      myformTobodyformat = {
        phone,
        password,
        redirectUrl,
      };
      // console.log('phoneloginbodyPassword',myformTobodyformat);
    } else {
      // email登入
      // 若未通過驗證，顯示錯誤訊息並結束函式
      if (!verifyEmail()) {
        setIsLoading(false);
        setRegxpError("請填寫正確Email資訊");
        return;
      }

      let email = myform.email;

      // 若自動填入，則email為自動填入值
      if (!!autoEmail.current) {
        email = autoEmail.current.value;
      }

      let password = myform.password;
      let redirectUrl = window.location.href + "SubmitPage";
      // // // console.log('redirectUrl', redirectUrl);
      // let redirectUrl = 'https://' + apiURL + '';
      // let redirectUrl = 'https://localhost:3000/SubmitPage';
      myformTobodyformat = {
        email,
        password,
        redirectUrl,
      };
      // // console.log('phoneloginbodyEmail',myformTobodyformat);
    }

    // 密碼比對
    if (!myform.password) {
      setIsLoading(false);
      setRegxpError("請填寫密碼欄位");
      return;
    }

    // Fetch取得公鑰
    let pkeyID = "";
    let pkey = "";
    fetch("https://uaa.kid-pro.com/api/uaa/auth/public_key", {
      method: "GET",
      // body: JSON.stringify(data),
      headers: {
        "Content-Type": "application/json",
        // 'Access-Control-Allow-Origin': '*',
        // 'Authorization': BearerToken
      },
    })
      .then((r) => r.json())
      .then((result) => {
        // console.log('public_key_result', result);
        if (result.statusCode === 200) {
          pkeyID = result.data.id;
          pkey = result.data.key;
          // // console.log('pkeyID', pkeyID);
          // // console.log('pkey', pkey);
          if (!!myformTobodyformat) {
            if (!!pkey) {
              // 加密資料
              let cipherLoginData,
                cipherLoginDataJSON = null;
              cipherLoginData = { data: cipherData(myformTobodyformat, pkey) };
              cipherLoginDataJSON = JSON.stringify(cipherLoginData);
              // // console.log('cipherLoginData',cipherRegisterData);
              // // console.log('cipherLoginDataJSON',JSON.stringify(cipherLoginData));
              if (!!cipherLoginData) {
                // 若資料已加密，則進行登入
                // 串接KidPro官網登入UAA
                fetch("https://uaa.kid-pro.com/api/uaa/login_crypto", {
                  method: "POST",
                  body: cipherLoginDataJSON,
                  headers: {
                    "Content-Type": "application/json",
                    "x-crypt-key-id": pkeyID,
                  },
                })
                  .then((r) => r.json())
                  .then((result) => {
                    // console.log('login_crypto_result', result);
                    if (result.statusCode === 200) {
                      window.localStorage.setItem(
                        "loginToken",
                        result.data.loginToken
                      );
                      let line_bindData = {};
                      let redirectUrl = window.location.href;
                      const accessToken = liff.getAccessToken();
                      line_bindData = {
                        email: myformTobodyformat.email,
                        redirectUrl: redirectUrl,
                        accessToken: accessToken,
                      };
                      // // console.log('登入成功，line_bindData',line_bindData);

                      const loginToken = result.data.loginToken;
                      const BearerToken = "Bearer " + loginToken;
                      fetch("https://uaa.kid-pro.com/api/uaa/line_bind", {
                        method: "POST",
                        body: JSON.stringify(line_bindData),
                        headers: {
                          "Content-Type": "application/json",
                          Authorization: BearerToken,
                        },
                      })
                        .then((r) => r.json())
                        .then((result) => {
                          // // console.log('result bind', result);
                          if (result.status === 200) {
                            // // console.log('成功 200');
                            // setNOuaabindLine(false);
                            setIsLoading(false);
                            // window.localStorage.setItem( 'userNormalLoggedIn', false );  // 20230802 強迫使用者用LINE登入：使用者先一般登入，再點選LINE註冊/登入，使用者取得LINE ACCESS TOKEN後，要輸入舊Wordpress帳號進行登入綁定LINE時
                            // // console.log('window.location.pathname.toLowerCase().indexOf(grantmembership)',window.location.pathname.toLowerCase().indexOf('grantmembership'));
                            // 綁定成功檢查使用者活動紀錄再跳轉
                            // 設定舊Wordpress帳號進行登入綁定LINE後的導向位置(在使用者未綁定LINE，而要綁舊Wordpress帳號，或用LINE創新Wordpress帳號時)，只要有新增新的path，就要在這裡做更動
                            if (
                              window.location.pathname
                                .toLowerCase()
                                .indexOf("grantmembership") > -1
                            ) {
                              window.location.assign(
                                window.location.protocol +
                                  "//" +
                                  window.location.host +
                                  "/GrantMembership"
                              );
                            } else if (
                              window.location.pathname
                                .toLowerCase()
                                .indexOf("groupbuy") > -1 &&
                              window.location.pathname
                                .toLowerCase()
                                .indexOf("groupbuycheckout") === -1 &&
                              window.location.pathname
                                .toLowerCase()
                                .indexOf("groupbuylistteams") === -1
                            ) {
                              // setUserNormalLoggedIn(true); // 設定使用者為一般登入進行登入
                              window.location.assign(
                                window.location.protocol +
                                  "//" +
                                  window.location.host +
                                  "/GroupBuy"
                              );
                            } else if (
                              window.location.pathname
                                .toLowerCase()
                                .indexOf("groupbuycheckout") > -1
                            ) {
                              // setUserNormalLoggedIn(true); // 設定使用者為一般登入進行登入
                              window.location.assign(
                                window.location.protocol +
                                  "//" +
                                  window.location.host +
                                  "/GroupBuyCheckout"
                              );
                            } else if (
                              window.location.pathname
                                .toLowerCase()
                                .indexOf("groupbuylistteams") > -1
                            ) {
                              // setUserNormalLoggedIn(true); // 設定使用者為一般登入進行登入
                              window.location.assign(
                                window.location.protocol +
                                  "//" +
                                  window.location.host +
                                  "/GroupBuyListTeams"
                              );
                            } else {
                              window.location.assign(
                                window.location.protocol +
                                  "//" +
                                  window.location.host +
                                  "/CheckActivity?redir=kidpro"
                              );
                            }

                            // 綁定成功跳轉
                            // // console.log( 'result.data.url', result.data.url );
                            // window.location.assign(result.data.url);
                          } else if (result.status === 400) {
                            setRegxpError("帳號或密碼錯誤，無法進行綁定！");
                            setIsLoading(false);
                            //// // console.log('失敗，錯誤代碼 400')
                          } else if (result.status === 403) {
                            setRegxpError("帳號或密碼錯誤，無法進行綁定！");
                            setIsLoading(false);
                            //// // console.log('失敗，錯誤代碼 403');
                          } else {
                            setRegxpError("帳號或密碼錯誤，無法進行綁定！");
                            // // console.log('失敗。');
                          }
                        })
                        .catch((error) => {
                          setIsLoading(false);
                          setRegxpError("伺服器錯誤，請稍後再試！");
                        });
                    } else if (result.statusCode === 400) {
                      setIsLoading(false);
                      setRegxpError("登入失敗，錯誤代碼 400");
                    } else if (result.statusCode === 403) {
                      setIsLoading(false);
                      setRegxpError("帳號或密碼錯誤，錯誤代碼 403");
                    } else {
                      setIsLoading(false);
                      setRegxpError("登入失敗，輸入的手機或Email不存在！");
                    }
                  })
                  .catch((error) => {
                    setIsLoading(false);
                    setRegxpError("伺服器錯誤，請稍後再試！");
                  }); // END Fetch 使用者登入
              } else {
                setRegxpError("錯誤，資料加密失敗！");
              }
            } else {
              setRegxpError("錯誤，缺少金鑰，無法加密資料！");
            }
          } else {
            setRegxpError("錯誤，未能取得表單資料！");
          }
        } else {
          setRegxpError("伺服器錯誤，請稍候再試！");
          // // console.log( 'result.status!==200');
        }
      })
      .catch((error) => {
        setRegxpError("伺服器錯誤，請稍候再試！");
        // // console.log(error);
      }); // END Fetch取得公鑰
  }; /* END Function 使用者點選登入 */

  // const whenSubmit = (event) => {
  //   event.preventDefault();

  //   //刪除前段錯誤訊息
  //   setRegxpError('');
  //   setIsLoading(true);

  //狀態清空，返回最初登入頁面
  let LIFF_STOREregister = window.localStorage.getItem("LIFF_STOREregister");
  // const ReturntoRegister =()=>{
  //   if(LIFF_STOREregister){
  //     window.localStorage.removeItem('LIFF_STOREregister');
  //   }
  //   liff.logout();
  //   setSheet('LoginIndex');
  //   setNOuaabindLine('');
  // }

  return (
    <>
      <Row>
        <Col>
          <Form className={style.form}>
            <Col className="m-2 mb-3">
              <div className={style.KidProLoginTitleBox}>
                <div className={style.KidProLoginMarkBox}>
                  <img
                    className={style.KidProLoginMark}
                    src={
                      "https://imagedelivery.net/-7odu1CEBhoPcuWSdxB8-g/8bbd1ae0-b158-4299-6d7c-b0dcf38d6000/public"
                    }
                  ></img>
                  <span className={style.KidProLoginMainTitle}>
                    LINE綁定／註冊
                  </span>
                </div>
                {/* <div className={style.KidProLoginTitle}>
                     <span >Line帳號名稱</span>
                   </div> */}
              </div>
            </Col>
            <Col>
              <div className="mb-3">
                {/* radio button name值一樣設定用戶只能擇一選 */}
                <input
                  type="radio"
                  name="radio"
                  id="KidProMember"
                  value="KidProMember"
                  className={style.radioButton}
                  checked={radioValue.member === "KidProMember"}
                  onChange={onChangeRadioValue}
                ></input>
                <label htmlFor="KidProMember" className={style.radioLabel}>
                  我已經是KidPro會員(登入即完成綁定)
                </label>
              </div>
            </Col>
            <Col className="mx-2 sm-mx-1">
              <Form.Group className="mb-3">
                <div
                  style={{
                    width: "100%",
                    display: "flex",
                    justifyContent: "space-between",
                  }}
                >
                  {LoginWay ? (
                    <>
                      <Form.Label className={style.PhoneLable}>
                        手機登入
                      </Form.Label>
                    </>
                  ) : (
                    <>
                      <Form.Label className={style.mailLable}>
                        電子郵件登入
                      </Form.Label>
                    </>
                  )}
                  {LoginWay ? (
                    <div
                      className={style.loginWayDirectMailSec}
                      onClick={toEmailLogin}
                    >
                      <span className={style.loginWayDirect}>
                        改用電子郵件登入&gt;
                      </span>
                    </div>
                  ) : (
                    <div
                      className={style.loginWayDirectPhoneSec}
                      onClick={toPhoneLogin}
                    >
                      <span href="#" className={style.loginWayDirect}>
                        改用手機號碼登入&gt;
                      </span>
                    </div>
                  )}
                </div>

                {LoginWay ? (
                  <>
                    <div className={style.PhoneSec}>
                      <select
                        className={style.countryCode}
                        id="countryCode"
                        onChange={changeFields}
                        value={myform.countryCode}
                        required
                      >
                        {coutryCodeArray.map((v, i) => {
                          return (
                            <>
                              <option href="#/action-1" key={i + 1}>
                                {v[1]}
                              </option>
                            </>
                          );
                        })}
                      </select>
                      <input
                        className={style.inputinfiled}
                        type="tel"
                        id="phone"
                        name="phone"
                        onChange={changeFields}
                        value={myform.phone}
                        ref={autoPhone}
                        pattern="[0-9|\-|\s]{5,20}"
                        autoComplete="false"
                        required
                      />
                    </div>
                  </>
                ) : (
                  <>
                    <input
                      className={style.inputinfiled}
                      type="email"
                      id="email"
                      name="email"
                      onChange={changeFields}
                      value={myform.email}
                      ref={autoEmail}
                      autoComplete="false"
                      required
                    />
                  </>
                )}
              </Form.Group>

              <Form.Group className="mb-4">
                <Form.Label className={style.pdsLable}>密碼</Form.Label>
                <input
                  className={style.inputinfiled}
                  type="password"
                  id="password"
                  name="password"
                  autoComplete="password"
                  onChange={changeFields}
                  value={myform.password}
                  required
                />
              </Form.Group>
            </Col>
            <Col>
              <div className="mb-3">
                <input
                  type="radio"
                  name="radio"
                  id="NOTKidProMember"
                  value="NOTKidProMember"
                  className={style.radioButton}
                  checked={radioValue.member === "NOTKidProMember"}
                  onChange={onChangeRadioValue}
                ></input>
                <label htmlFor="NOTKidProMember" className={style.radioLabel}>
                  我還不是KidPro會員，我要註冊新會員
                </label>
              </div>
            </Col>
            <Col>
              <div className={style.RegxpErrorSec}>
                <p className={style.RegxpError}>{RegxpError}</p>
              </div>
            </Col>
            <Col className="mb-4">
              <div className={style.loginbuttosec}>
                {isLoading ? (
                  <LoadingSpinner></LoadingSpinner>
                ) : radioValue.member === "KidProMember" ? (
                  <button
                    type="button"
                    className={style.loginbutton}
                    onClick={whenSubmit}
                  >
                    登入
                  </button>
                ) : (
                  <button
                    type="button"
                    className={style.loginbutton}
                    onClick={(event) => {
                      event.preventDefault();
                      setSheet("registerPhone");
                      window.localStorage.setItem("lineRegister", "true");
                    }}
                  >
                    註冊新會員
                  </button>
                )}
              </div>
            </Col>
            {/* <Col>
                <div className={style.Return} onClick={ReturntoRegister}>
                  <span>不綁定LINE直接登入</span>
                </div>
              </Col> */}
          </Form>
        </Col>
      </Row>
    </>
  );
}

export default NoLineDataLogin;
