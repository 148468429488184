import React from "react";
import style from "./Spinner.module.css";
import "./Spinner.css";

export default function Spinner() {
  return (
    // <div className={style.loadingSpinnerDiv}>
    //   <div className={style.loadingSpinner}/>
    // </div>
    // <div className={style.spinnerContainer}>
    //     <div className={style.loadingSpinner}/>
    // </div>
    <div className={style.spinnerContainer}>
      <img className={style.spinnerImg} src="https://imagedelivery.net/-7odu1CEBhoPcuWSdxB8-g/035eab44-93d0-4cef-5c6e-52317441f100/public"></img>
    </div>
  );
}