import liff from '@line/liff/dist/lib';
const lineid = process.env.REACT_APP_LINE_APP_ID;

function InitLine() {

  return (
    new Promise(resolve => {
        // wait for facebook sdk to initialize before starting the react app
        window.fbAsyncInit = function () {
            liff.init({
                liffId: lineid, // Use own liffId
                // withLoginOnExternalBrowser: true, // Enable automatic login processFBStatus
               
            }).catch((err) => {
                // Todo: retry init.
                //// // console.log('err',err);
            });
  
        };
      
    })
    
  )
}

export default InitLine