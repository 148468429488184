import { Steps, Typography } from 'antd';
const { Title } = Typography;
const description = 
<>
  <div style={{height:'100px'}}>
    <span>lorem</span>
  </div>
</>;
const StepsDEMO = () => (
  <><Steps
    direction="vertical"
    items={[
      {
        description,
      },
      {
        description,
      },
      {
        description,
      },
    ]} />
    <Title level={2}>h2. Ant Design</Title></>
);
export default StepsDEMO;