import React from "react";
import style from "./SpinnerFullScreen.module.css";
import "./Spinner.css";

export default function SpinnerFullScreen() {
  return (
    <section className={style.spinnerSec}>
      <div className={style.spinnerSecDiv}>
        <img
          className={style.spinnerImg}
          src="https://imagedelivery.net/-7odu1CEBhoPcuWSdxB8-g/035eab44-93d0-4cef-5c6e-52317441f100/public"
        ></img>
        <div className={style.container}>
          <div className={`${style.text} ${style.textBackground}`}>
            登入中請稍候 . . .
          </div>
          <div className={`${style.text} ${style.textColor}`}>
            登入中請稍候 . . .
          </div>
        </div>
      </div>
    </section>
  );
}
