import React from 'react';
import style from '../styles/CheckMark.module.css';

function CheckMark() {
  return (
    <>
      <svg  className={style.checkmark} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 52 52">
        <path className={style.checkmark__check} fill="none" d="M14.1 27.2l7.1 7.2 16.7-16.8"/>
      </svg>
    </>
  )
}

export default CheckMark;