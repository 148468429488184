import React from 'react'
import { useState, useEffect, useRef, useContext } from 'react';
import { Col, Container, Form, Row } from "react-bootstrap";
import style from './styles/RegisterSuccess.module.css'
// import { sendRegisterVeriLink } from '../components/ModuleSendEmail'
import LoadingSpinner from '../../Components/Spinner/Spinner'; // 引入loading 轉圈圈元件
import AuthContext from '../login/components/AuthContext'



function RegisterSuccess(props) {

    const { email,authorized, setAuth, auth,setEmail } = useContext(AuthContext); // LINE登入狀態

    const {setSheet}=props;
    const {setsmsveristatus}=props;  // set-檢查使用者是否有通過簡訊驗證，以啟用下一步按鈕(上層傳入)
    const {smsveristatus}=props;  // status-檢查使用者是否有通過簡訊驗證，以啟用下一步按鈕(上層傳入)

    const [isLoading, setIsLoading] = useState(false); // 設定loading 轉圈圈顯示與否
    const [RegxpError, setRegxpError] = useState(''); // 顯示錯誤訊息
    const [userStatus, setUserStatus] = useState('login'); // 使用者要註冊或登入

    // 檢查使用者是否已輸入過手機，並設定預設值
    const storagePhoneNumber = !! window.localStorage.getItem('registerPhoneNumber') ? window.localStorage.getItem('registerPhoneNumber') : '';
    window.localStorage.setItem( 'registerPhoneNumber', storagePhoneNumber ); 

    return (
        <>
        <Row>
            <Col>
                <Col className="mb-3">
                    <div className={style.KidProLoginTitleBox}>
                        <span className={style.KidProLoginTitle}>註冊成功</span>
                    </div>
                </Col>
                <Col className="mb-4">
                    <div className={style.KidProLoginDscrptBox}>
                        <span className={style.mailLable}>恭喜您已成功註冊KidPro！</span>
                        <span className={style.mailLable}>請點選您註冊信箱的驗證信連結，再由下方的登入按鈕進行登入！</span>
                    </div>
                </Col>    
                <Col>
                    <div className={style.RegxpErrorSec}>
                        <p className={style.RegxpError}>{RegxpError}</p>
                    </div>
                </Col>          
                <Col className="mb-4">
                    <div className={style.loginbuttosec}>
                        <button type="button" className={style.nextStepButton} onClick={ () => { 
                            setRegxpError('');
                            setAuth({...auth,authorized:false});
                            setEmail(null);
                            setSheet('LoginIndex');
                            localStorage.clear();
                        }}>登入</button>
                    </div>     
                </Col>
            </Col>
        </Row>
        </>
    )
}

export default RegisterSuccess;