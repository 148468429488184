import React, {useState, useEffect} from 'react'
import { useCookies } from 'react-cookie'; // 引入useCookies hook
// import ReactGA from "react-ga4"; // GA4
// import ReactPixel from 'react-facebook-pixel'; // FB Pixel

/*
 * UTM事件組態設定
 * itemIds: product id array
 * contentName: '瀏覽【三人成團購課－選課/創團頁面】'
 * ContentCategory: 'kidpro_marketing_groupbuy'
 * Send pageview with a custom path
 * Utm.ga4.send({ hitType: "pageview", page: window.location.pathname });
 * Utm.ga4.send({ hitType: "scrolls", page: window.location.pathname });
 * Utm.ga4.event('login');
 * Send a custom event:
 * Utm.ga4.event({
 *  category: "testCategory",
 *  action: "testAction",
 *  label: "testLabel",
 * });
 */
export let utmEventsInit = false; // UTM事件組態設定
let globalPixelUserId, globalGa4UserId, globalContentName, globalContentCategory, globalItemIds, globalItems, globalCurrency, globalValue, globalOrderNumber, globalShareMethod
globalPixelUserId = globalGa4UserId = globalContentName = globalContentCategory = globalItemIds = globalItems = globalCurrency = globalValue = globalOrderNumber = globalShareMethod = undefined;
const utmEnv = process.env.REACT_APP_UTM;
const UtmEvents = (contentName, contentCategory, itemIds, items, currency, value, orderNumber, shareMethod) => {
    const [cookies, setCookie] = useCookies(['Utm']); // useCookies hook
    const pixelUserId = cookies?._fbp;
    const ga4UserId = cookies?._ga;
    useEffect(() => {
        globalPixelUserId = pixelUserId;
        globalGa4UserId = ga4UserId;
        globalContentName = contentName;
        globalContentCategory = contentCategory;
        globalItemIds = itemIds;
        globalItems = items;
        globalCurrency = currency;
        globalValue = value;
        globalOrderNumber = orderNumber;
        globalShareMethod = shareMethod;
        utmEventsInit = true;
    }, [contentName, contentCategory, itemIds, items, currency, value, orderNumber, shareMethod]);
    // useEffect(() => {
    //     // console.log('{pixelUserId, ga4UserId, contentName, contentCategory, itemIds, items, currency, value, orderNumber, shareMethod}', {pixelUserId, ga4UserId, itemIds, contentName, contentCategory, items, currency, value, orderNumber, shareMethod});
    // }, [contentName, contentCategory, items, itemIds, currency, value, orderNumber, shareMethod]);
    // useEffect(() => {
    //     // console.log('{globalPixelUserId, globalGa4UserId, globalContentName, globalContentCategory, globalItemIds, globalItems, globalCurrency, globalValue, globalOrderNumber, globalShareMethod}', {globalPixelUserId, globalGa4UserId, globalItemIds, globalContentName, globalContentCategory, globalItems, globalCurrency, globalValue, globalOrderNumber, globalShareMethod});
    // }, [globalContentName, globalContentCategory, globalItemIds, globalItems, globalCurrency, globalValue, globalOrderNumber, globalShareMethod]);
} /*** END UTM事件組態設定 ***/

/*** Event Option Value Handling ***/
/* Pixel處理購買商品細節 */
const pixelHandleItems = (globalItems) => {
    const itemArray = [];
    globalItems.map((item, index) => {
        const itemObj = {
            id: item?.id,
            quantity: item?.quantity,
            price: item?.price
        }
        itemArray.push(itemObj);
    })
    return itemArray;
}
/* GA4處理購買商品細節 */
const ga4HandleItems = (globalItems) => {
    const itemArray = [];
    globalItems.map((item, index) => {
        const itemObj = {
            item_id: item?.id,
            quantity: item?.quantity,
            price: item?.price
        }
        itemArray.push(itemObj);
    })
    return itemArray;
} 
/* 處理購買商品總數(num_items) */
const handleNumItems = (globalItems) => {
    let numItems = 0;
    globalItems.map((item, index) => {
        numItems += item?.quantity;
    })
    return numItems;
}/*** End Event Option Value Handling ***/

/*** Event Functions ***/
/* 瀏覽頁面 */
export const utmPageView = (Utm, eventID, contentName, contentCategory) => {
    // console.log('eventID', eventID)
    /* FB Pixel */
    const pixelEventOptions = { // FB Pixel Event Options Setting
        content_ids: globalPixelUserId,
        content_name: contentName??globalContentName,
        content_category: contentCategory??globalContentCategory,
        content_type: 'content'
    }
    // console.log('UtmPageView: pixelEventOptions', pixelEventOptions);
    // console.log('UtmPageView: Utm.pixelId',Utm.pixelId)
    // Utm.pixel.track('ViewContent', pixelEventOptions); // FB Pixel Send Event
    // console.log('UtmPageView: Utm.pixelId',Utm.pixelId)
    if( Utm.pixelId && utmEnv === "production" ) {
        window.fbq('track', 'ViewContent', pixelEventOptions, eventID);
    }
    /* GA4 */
    const ga4EventOptions = { // GA4 Event Options Setting
        client_id: globalGa4UserId,
        page_title: contentName??globalContentName,
        page_location: window.location.href,
        language: 'zh_tw',
        page_encoding: 'UTF-8',
        user_agent: window.navigator.userAgent
    }
    // console.log('UtmPageView: ga4EventOptions', ga4EventOptions);
    // console.log('UtmPageView: Utm.ga4Id',Utm.ga4Id)
    if( Utm.ga4Id && utmEnv === "production" ) {
        Utm.ga4.event( 'page_view', ga4EventOptions); // GA4 Bind page_view Event
        Utm.ga4.send({ hitType: "event", page: window.location.pathname, title: contentName??globalContentName }); // GA4 Send Event
    }
}
/* 加入購物車 */
export const utmAddToCart = (Utm, eventID,  contentName, itemIds, items, currency, value) => {
    /* FB Pixel */
    const pixelEventOptions = { // FB Pixel Event Options Setting
        content_ids: Array.isArray(itemIds)?itemIds:globalItemIds,
        content_name: contentName??globalContentName,
        content_type: 'product_group',
        contents: Array.isArray(items)?pixelHandleItems(items):pixelHandleItems(globalItems),
        currency: currency??globalCurrency,
        value: value??globalValue
    }
    // console.log('UtmAddToCart: pixelEventOptions', pixelEventOptions);
    // console.log('UtmAddToCart: Utm.pixelId',Utm.pixelId)
    // Utm.pixel.track('AddToCart', pixelEventOptions); // FB Pixel Send Event
    if( Utm.pixelId && utmEnv === "production" ) {
        window.fbq('track', 'AddToCart', pixelEventOptions, eventID);
    }
    /* GA4 */
    const ga4EventOptions = { // GA4 Event Options Setting
        items: Array.isArray(items)?ga4HandleItems(items):ga4HandleItems(globalItems),
        currency: currency??globalCurrency,
        value: value??globalValue
    }
    // console.log('UtmAddToCart: ga4EventOptions', ga4EventOptions); // GA4 Event Options Setting
    // console.log('UtmAddToCart: Utm.ga4Id',Utm.ga4Id)
    if( Utm.ga4Id && utmEnv === "production" ) {
        Utm.ga4.event('add_to_cart', ga4EventOptions); // GA4 Bind add_to_cart Event
        Utm.ga4.send({ hitType: "event", page: window.location.pathname, title: contentName??globalContentName }); // GA4 Send Event
    }
}
/* 結帳 */
export const utmCheckout = (Utm, eventID,  contentName, contentCategory, itemIds, items, currency, value) => {
    const pixelEventOptions = { // FB Pixel Event Options Setting
        content_ids: Array.isArray(itemIds)?itemIds:globalItemIds,
        content_category: contentCategory??globalContentCategory,
        contents: Array.isArray(items)?pixelHandleItems(items):pixelHandleItems(globalItems),
        currency: currency??globalCurrency,
        value: value??globalValue,
        num_items: Array.isArray(items)?handleNumItems(items):handleNumItems(globalItems)
    }
    // console.log('UtmCheckout: pixelEventOptions', pixelEventOptions);
    // console.log('UtmCheckout: Utm.pixelId',Utm.pixelId)
    // Utm.pixel.track('InitiateCheckout', pixelEventOptions); // FB Pixel Send Event
    if( Utm.pixelId && utmEnv === "production" ) {
        window.fbq('track', 'InitiateCheckout', pixelEventOptions, eventID);
    }
    const ga4EventOptions = { // GA4 Event Options Setting
        items: Array.isArray(items)?ga4HandleItems(items):ga4HandleItems(globalItems),
        currency: currency??globalCurrency,
        value: value??globalValue,
    }
    // console.log('UtmCheckout: ga4EventOptions', ga4EventOptions); // GA4 Event Options Setting
    // console.log('UtmCheckout: Utm.ga4Id',Utm.ga4Id)
    if( Utm.ga4Id && utmEnv === "production" ) {
        Utm.ga4.event('begin_checkout', ga4EventOptions); // GA4 Bind begin_checkout Event
        Utm.ga4.send({ hitType: "event", page: window.location.pathname, title: contentName??globalContentName }); // GA4 Send Event 
    }
}
/* 完成購買 */
export const utmPurchase = (Utm, eventID,  contentName, itemIds, items, currency, value, orderNumber) => {
    const pixelEventOptions = { // FB Pixel Event Options Setting
        content_ids: Array.isArray(itemIds)?itemIds:globalItemIds,
        content_name: contentName??globalContentName,
        content_type: 'product_group',
        contents: Array.isArray(items)?pixelHandleItems(items):pixelHandleItems(globalItems),
        currency: currency??globalCurrency,
        value: value??globalValue,
    }
    // console.log('UtmPurchase: pixelEventOptions', pixelEventOptions);
    // console.log('UtmPurchase: Utm.pixelId',Utm.pixelId)
    // Utm.pixel.track('Purchase', pixelEventOptions); // FB Pixel Send Event
    if( Utm.pixelId && utmEnv === "production" ) {
        window.fbq('track', 'Purchase', pixelEventOptions, eventID);
    }
    const ga4EventOptions = { // GA4 Event Options Setting
        transaction_id: orderNumber??globalOrderNumber,
        items: Array.isArray(items)?ga4HandleItems(items):ga4HandleItems(globalItems),
        currency: currency??globalCurrency,
        value: value??globalValue,
    }
    // console.log('utmPurchase: ga4EventOptions', ga4EventOptions); // GA4 Event Options Setting
    // console.log('utmPurchase: Utm.ga4Id', Utm.ga4Id)
    if( Utm.ga4Id && utmEnv === "production" ) {
        Utm.ga4.event('purchase', ga4EventOptions); // GA4 Bind purchase Event
        Utm.ga4.send({ hitType: "event", page: window.location.pathname, title: contentName??globalContentName }); // GA4 Send Event
    }
}
/* 分享連結 */
export const utmShare = (Utm, eventID,  contentName, contentCategory, shareMethod) => {
    // const pixelEventOptions = { // FB Pixel Event Options Setting
    //     category: globalContentCategory,
    //     action: shareMethod??globalShareMethod,
    //     label: contentName
    // }
    const pixelEventOptions = { // FB Pixel Event Options Setting
        content_name: contentName??globalContentName,
        content_category: contentCategory??globalContentCategory
    }
    // console.log('UtmShare: pixelEventOptions', pixelEventOptions);
    // console.log('UtmShare: Utm.pixelId',Utm.pixelId)
    // Utm.pixel.track('Lead', pixelEventOptions); // FB Pixel Send Event, 'Lead' refer to potential customers
    if( Utm.pixelId && utmEnv === "production" ) {
        window.fbq('track', 'Lead', pixelEventOptions, eventID);
    }
    const ga4EventOptions = { // GA4 Event Options Setting
        content_type: 'share',
        method: shareMethod??globalShareMethod,
        item_id: contentName??globalContentName,
    }
    // console.log('utmShare: ga4EventOptions', ga4EventOptions); // GA4 Event Options Setting
    // console.log('utmShare: Utm.ga4Id', Utm.ga4Id)
    if( Utm.ga4Id && utmEnv === "production" ) {
        Utm.ga4.event('share', ga4EventOptions); // GA4 Bind share Event
        Utm.ga4.send({ hitType: "event", page: window.location.pathname, title: contentName??globalContentName }); // GA4 Send Event
    }
}/*** END Event Functions ***/  

export default UtmEvents
