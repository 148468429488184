import React, { useState, useContext, useEffect, useRef } from "react";
import AuthContext from "../UaaPage/login/components/AuthContext";
import style from "./styles/GroupBuyListTeams.module.css";
import "./styles/GroupBuyListTeams.css";
import "./styles/AdvertisingPage.css";
import "./styles/antStyle.css";
import { Container, Row, Col, Button } from "react-bootstrap";
import LoadingSpinner from "../Components/Spinner/Spinner";
import Modal from "react-bootstrap/Modal";
import popupStyle from "../Components/styles/popup.module.css";
import LoginParent from "../UaaPage/login/LoginParent";
import "bootstrap/dist/css/bootstrap.min.css";
import CheckMark from "./components/CheckMark";
import * as Scroll from "react-scroll";
import {
  Element,
  Events,
  animateScroll as scroll,
  scroller,
} from "react-scroll";
import dayjs from "dayjs";
// import MemberSvg from './imgs/member.svg'

/*** GroupBuy Page ***/
import GroupBuyCreateGroup from "./GroupBuyCreateGroup";
import GroupBuyCheckoutCreateTeam from "./GroupBuyCheckoutCreateTeam";
import GroupBuyCheckoutAddTeam from "./GroupBuyCheckoutAddTeam";
import jwt_decode from "jwt-decode";

/***  antd ***/
import {
  Steps,
  Typography,
  Layout,
  Collapse,
  Button as AntButton,
  Table,
  Space,
} from "antd";
import { CaretUpOutlined, CaretDownOutlined } from "@ant-design/icons";
import "./styles/antStyle.css";

import { useCookies } from "react-cookie"; // 引入useCookies hook
import ReactGA from "react-ga4"; // GA4
import ReactPixel from "react-facebook-pixel"; // FB Pixel

import liff from "@line/liff"; // liff SDK
import Footer from "../Components/Footer/Footer";

/*** UTM ***/
import UtmConfig from "../Components/Utm/UtmConfig";
import UtmEvents, {
  utmEventsInit,
  utmPageView,
} from "../Components/Utm/UtmEvents";
import { ErrorMessage } from "formik";

function GroupBuyListTeams(props) {
  const { Sheet, setSheet } = props;
  const {
    authorized,
    email,
    setEmail,
    auth,
    setAuth,
    userNormalLoggedIn,
    setUserNormalLoggedIn,
  } = useContext(AuthContext);
  const [NOuaabindLine, setNOuaabindLine] = useState(false); // 設定登入頁面line login 無綁定uaa
  const [Loginparent, setLoginparent] = useState(0); // 設定login parent
  const [LoginToken, setLoginToken] = useState(""); // 設定login token
  const [RegxpError, setRegxpError] = useState(""); // 顯示錯誤訊息

  const backendURL = process.env.REACT_APP_BACKENDURL; // 首頁URL
  const apiURL = process.env.REACT_APP_APIURL; // API URL
  const microservApiURL = process.env.REACT_APP_MICROSERV_APIURL; // microserve API URL
  let LIFF_STORE = window.localStorage.getItem("LIFF_STORE");
  let LIFF_STOREregister = window.localStorage.getItem("LIFF_STOREregister");
  const lineAccessToken = !!window.localStorage.getItem("lat")
    ? window.localStorage.getItem("lat")
    : "";
  const [ShowCheckMark, setShowCheckMark] = useState(false); // 設定copy按鈕反饋checkMark
  const [loginTokenState, setloginTokenState] = useState("");
  const [pidSelectedArray, setPidSelectedArray] = useState([]); // 設定選擇的課程pid
  const [userProductsArray, setUserProductsArray] = useState([]); // 設定使用者已購買的課程pid
  const [orderNum, setOrderNum] = useState(undefined); // 設定訂單編號(tappay驗證錯誤重刷)

  /***  Ant API ***/
  const { Title } = Typography; // 標題
  const { Content } = Layout; // 主要內容區塊(Layout本身是組件，也可當API使用)
  const { StepItem } = Steps;
  const { Panel } = Collapse;

  const kidprologinURL = process.env.REACT_APP_KIDPROLOGINURL; // KidPro 登入導向URL
  const uaaApiURL = process.env.REACT_APP_UAA_APIURL; // UAA API URL
  const lineid = process.env.REACT_APP_LINE_APP_ID;
  const kidprocertiURL = "https://kid-pro.com/my-account/my-certificates/"; // KidPro 完課證明頁面
  const cc02URL = "https://kid-pro.com/product/cc-0-2/";
  const si02URL = "https://kid-pro.com/product/si0-2/";
  const si212URL = "https://kid-pro.com/product/si2-12/";
  const ae31URL = "https://kid-pro.com/product/ae3-1/";

  let loginToken = window.localStorage.getItem("loginToken")
    ? window.localStorage.getItem("loginToken")
    : ""; // 取得Login Token
  let gotoKidproCerti = window.localStorage.getItem("loginToken")
    ? kidprologinURL + loginToken + "&redirectUrl=" + kidprocertiURL
    : kidprocertiURL; // 若Login Token存在，則KidPro LOGO導向首頁並登入；否則僅導向完課證明頁面
  const userNormalLoggedInStorage =
    window.localStorage.getItem("userNormalLoggedIn"); // 知道使用者是否先前是用一般登入
  let cc02 = window.localStorage.getItem("loginToken")
    ? kidprologinURL + loginToken + "&redirectUrl=" + cc02URL
    : cc02URL;
  let si02 = window.localStorage.getItem("loginToken")
    ? kidprologinURL + loginToken + "&redirectUrl=" + si02URL
    : si02URL;
  let si212 = window.localStorage.getItem("loginToken")
    ? kidprologinURL + loginToken + "&redirectUrl=" + si212URL
    : si212URL;
  let ae31 = window.localStorage.getItem("loginToken")
    ? kidprologinURL + loginToken + "&redirectUrl=" + ae31URL
    : ae31URL;

  /***** Ant Table *****/
  const [data, setData] = useState(); // 放入Table的資料狀態
  useEffect(() => {
    // console.log('data', data)
  }, [data]);
  const [loading, setLoading] = useState(false); // Table Loading Spinner
  const [tableParams, setTableParams] = useState({
    // 頁碼轉換
    pagination: {
      current: 1, // 目前頁碼
      pageSize: 5, // 一頁幾筆
    },
  });
  const [filterDropdownOpen, setFilterDropdownOpen] = useState(false); // 受控篩選表單顯示狀態
  const [filteredInfo, setFilteredInfo] = useState({}); // 受控篩選值狀態
  useEffect(() => {
    // console.log('filteredInfo', filteredInfo);
  }, [filteredInfo]);
  const [sortedInfo, setSortedInfo] = useState({}); // 受控排序狀態
  useEffect(() => {
    // console.log('sortedInfo', sortedInfo);
  }, [sortedInfo]);
  const [timeOrder, setTimeOrder] = useState("ASC");
  const [memberOrder, setMemberOrder] = useState("DESC");
  const [courseOrder, setCourseOrder] = useState("ASC");

  /*** 改重置按鈕文字 ***/
  const changeResetBtnText = () => {
    // console.log("changeResetBtnText");
    const antBtn = document?.querySelector(".ant-btn-link");
    if (antBtn?.innerText === "Reset") {
      antBtn.innerHTML = "<span>重置</span>";
    }
  };
  // useEffect(() => {
  //   changeResetBtnText();
  // }, [])

  // const dataTest = [
  //   {
  //     key: "1",
  //     name: "John Brown",
  //     age: 32,
  //     address: "New York No. 1 Lake Park"
  //   },
  //   {
  //     key: "2",
  //     name: "Jim Green",
  //     age: 42,
  //     address: "London No. 1 Lake Park"
  //   },
  //   {
  //     key: "3",
  //     name: "Joe Black",
  //     age: 32,
  //     address: "Sydney No. 1 Lake Park"
  //   },
  //   {
  //     key: "4",
  //     name: "Jim Red",
  //     age: 32,
  //     address: "London No. 2 Lake Park"
  //   }
  // ];

  /*** 取得未成團資料 ***/
  const fetchTeamsList = async () => {
    setLoading(true);
    if (loginToken) {
      const BearerToken = "Bearer " + loginToken;
      const createdTimeAsc = true;
      const memberCountAsc = false;
      // *   33589 嬰幼兒 父母必修課
      // *   20307 嬰幼兒 感覺統合啟蒙課程
      // *   100358 兒童 感覺統合訓練課程
      // *   60464 兒童 注意力與執行力訓練課程( 附教具 )
      // *   231206 兒童/成人 玩美臉型自信開口 全方位口肌訓練課程( 附教具 )
      const courseIds = ["33589", "20307", "100358", "60464", "231206"];
      // const courseIds = [];
      const limit = 200; // 最多撈出幾筆
      const params = new URLSearchParams();
      params.append("createdTimeAsc", createdTimeAsc);
      params.append("memberCountAsc", memberCountAsc);
      for (const courseId of courseIds) {
        params.append("courseIds", courseId);
      }
      params.append("limit", limit);
      const queryString = params.toString();
      // // console.log(queryString);
      // const getUrlString = new URLSearchParams(queryString);
      // const getCourseIds = getUrlString.getAll('courseIds')
      // // console.log("getCourseIds", getCourseIds)
      await fetch(
        "https://" + microservApiURL + "/team/notfull?" + queryString,
        {
          method: "GET",
          headers: {
            Authorization: BearerToken,
            Accept: "application/json",
            "Content-Type": "application/json",
          },
        }
      )
        .then((r) => r.json())
        .then((result) => {
          // console.log('fetchTeamsList', result);
          // result[2]?.members?.push({userId: '12345'}); // 測試用
          // result[0]?.products?.pop(); // 測試用
          // result[0]?.products?.pop(); // 測試用
          // result[0]?.products?.pop(); // 測試用
          // result[0]?.products?.pop(); // 測試用
          // result[1]?.products?.pop(); // 測試用
          // result[1]?.products?.pop(); // 測試用
          // result[1]?.products?.pop(); // 測試用
          // result[2]?.products?.pop(); // 測試用
          // result[2]?.products?.pop(); // 測試用
          // result[3]?.products?.pop(); // 測試用
          setData(result);
          // setData(dataTest); // 測試用
          setLoading(false);
        })
        .catch((error) => {
          // console.log('error',error);
          setLoading(false);
        });
    } else {
      setRegxpError("請先登入！");
    }
  };
  useEffect(() => {
    fetchTeamsList();
  }, []);

  // const fetchData = async() => {
  //   // console.log('tableParams', tableParams)
  //   // console.log('getRandomuserParams(tableParams)', getRandomuserParams(tableParams))
  //   // console.log('qs.stringify(getRandomuserParams(tableParams)', qs.stringify(getRandomuserParams(tableParams)))
  //   fetch(`https://randomuser.me/api?${qs.stringify(getRandomuserParams(tableParams))}`)
  //   .then((res) => res.json())
  //   .then(({ results }) => {
  //     // console.log(results);
  //     setData(results);
  //     setLoading(false);
  //     setTableParams({
  //       ...tableParams,
  //       pagination: {
  //         ...tableParams.pagination,
  //         total: 200,
  //         // 200 is mock data, you should read it from server
  //         // total: data.totalCount,
  //       },
  //     });
  //   });
  // }
  // useEffect(() => {
  //   fetchData();
  // }, [JSON.stringify(tableParams)]);

  /*** Table 點選頁碼、篩選、排序時觸發  ***/
  const handleTableChange = (pagination, filters, sorter) => {
    // console.log({pagination, filters, sorter})
    // changeResetBtnText(); // 改變Reset Button文字
    setTableParams({
      pagination,
      filters,
      ...sorter,
    });
    // `dataSource` is useless since `pageSize` changed
    setFilteredInfo(filters);
    setSortedInfo(sorter);
    if (pagination.pageSize !== tableParams.pagination?.pageSize) {
      setData([]);
    }
  };

  // const setAgeSort = () => {
  //   if( ! sortedInfo?.order || sortedInfo?.order === "descend" ) {
  //     setSortedInfo({
  //       order: 'ascend',
  //       columnKey: 'age',
  //     });
  //   } else {
  //     setSortedInfo({
  //       order: 'descend',
  //       columnKey: 'age',
  //     });
  //   }
  // };

  /*** 時間排序功能 ***/
  const setTimeSort = () => {
    // console.log("setTimeSort: sortedInfo?.order", sortedInfo?.order)
    if (!sortedInfo?.order || sortedInfo?.order === "descend") {
      setSortedInfo({
        order: "ascend",
        columnKey: "createdAt",
      });
      setTimeOrder("ASC");
    } else {
      setSortedInfo({
        order: "descend",
        columnKey: "createdAt",
      });
      setTimeOrder("DESC");
    }
  };

  /*** 人數排序功能 ***/
  const setMemberCountSort = () => {
    // console.log("setMemberCountSort: sortedInfo?.order", sortedInfo?.order)
    if (!sortedInfo?.order || sortedInfo?.order === "ascend") {
      setSortedInfo({
        order: "descend",
        columnKey: "memberCount",
      });
      setMemberOrder("DESC");
    } else {
      setSortedInfo({
        order: "ascend",
        columnKey: "memberCount",
      });
      setMemberOrder("ASC");
    }
  };

  /*** 課程數量排序功能 ***/
  const setCourseCountSort = () => {
    // console.log("setMemberCountSort: sortedInfo?.order", sortedInfo?.order)
    if (!sortedInfo?.order || sortedInfo?.order === "descend") {
      setSortedInfo({
        order: "ascend",
        columnKey: "courseCount",
      });
      setCourseOrder("ASC");
    } else {
      setSortedInfo({
        order: "descend",
        columnKey: "courseCount",
      });
      setCourseOrder("DESC");
    }
  };

  /*** 依課程名稱篩選 ***/
  const filterCourse = () => {
    setFilterDropdownOpen(!filterDropdownOpen);
    // console.log(filterDropdownOpen)
    // // console.log("sortedInfo?.order", sortedInfo?.order)
    // if( ! sortedInfo?.order || sortedInfo?.order === "descend" ) {
    //   setSortedInfo({
    //     order: 'ascend',
    //     columnKey: 'createdAt',
    //   });
    // } else {
    //   setSortedInfo({
    //     order: 'descend',
    //     columnKey: 'createdAt',
    //   });
    // }
  };
  // const timeList = [dayjs('2018-04-04T16:00:00.000Z'), dayjs('2019-04-04T16:00:00.000Z'), dayjs('2020-04-04T16:00:00.000Z')];
  // useEffect(() => {
  //   // console.log('timeList', timeList);
  // }, [timeList])
  // *   33589 嬰幼兒 父母必修課
  // *   20307 嬰幼兒 感覺統合啟蒙課程
  // *   100358 兒童 感覺統合訓練課程
  // *   60464 兒童 注意力與執行力訓練課程( 附教具 )
  // *   231206 兒童/成人 玩美臉型自信開口 全方位口肌訓練課程( 附教具 )
  // const courseList = {
  //   '33589': <img className={`${style.gbImgCoursePhone}`} src="https://imagedelivery.net/-7odu1CEBhoPcuWSdxB8-g/8d6b5aa3-343e-4635-7133-c77801ab8200/public" onClick={() => { window.open("https://kid-pro.com/product/cc-0-2/") }}></img>,
  //   '20307': <img className={style.gbImgCoursePhone} src="https://imagedelivery.net/-7odu1CEBhoPcuWSdxB8-g/8b0380e3-4473-41f5-40df-5f19d1262200/public" onClick={() => { window.open("https://kid-pro.com/product/si0-2/") }}></img>,
  //   '100358': <img className={style.gbImgCoursePhone} src="https://imagedelivery.net/-7odu1CEBhoPcuWSdxB8-g/16fe41e6-39b5-4739-ec3f-b9014e474800/public" onClick={() => { window.open("https://kid-pro.com/product/si-2-12/") }}></img>,
  //   '60464': <img className={style.gbImgCoursePhone} src="https://imagedelivery.net/-7odu1CEBhoPcuWSdxB8-g/f75dbe6d-2a89-457b-3018-e10af6302700/public" onClick={() => { window.open("https://kid-pro.com/product/ae3-1/") }}></img>,
  //   '231206': <img className={style.gbImgCoursePhone} src="https://imagedelivery.net/-7odu1CEBhoPcuWSdxB8-g/ac858e5e-5d4f-4d8f-31d2-9f5a64842800/public" onClick={() => { window.open("https://kid-pro.com/product/oral-2-12/") }}></img>,
  // };
  /*** Table課程列表 ***/
  const courseList = {
    33589: (
      <span
        className={`${style.gbTableLink}`}
        onClick={() => {
          window.open("https://kid-pro.com/product/cc-0-2/");
        }}
      >
        ．嬰幼兒 父母必修課
      </span>
    ),
    20307: (
      <span
        className={style.gbTableLink}
        onClick={() => {
          window.open("https://kid-pro.com/product/si0-2/");
        }}
      >
        ．嬰幼兒 感覺統合啟蒙課程
      </span>
    ),
    100358: (
      <span
        className={style.gbTableLink}
        onClick={() => {
          window.open("https://kid-pro.com/product/si-2-12/");
        }}
      >
        ．兒童 感覺統合訓練課程
      </span>
    ),
    60464: (
      <span
        className={style.gbTableLink}
        onClick={() => {
          window.open("https://kid-pro.com/product/ae3-1/");
        }}
      >
        ．兒童 注意力與執行力訓練課程（附教具）
      </span>
    ),
    231206: (
      <span
        className={style.gbTableLink}
        onClick={() => {
          window.open("https://kid-pro.com/product/oral-2-12/");
        }}
      >
        ．兒童/成人 玩美臉型自信開口 全方位口肌訓練課程（附教具）
      </span>
    ),
  };
  /*** Table縱向欄位設定 ***/
  const columns = [
    // {
    //   title: "Age",
    //   dataIndex: "age",
    //   key: "age",
    //   sorter: (a, b) => a.age - b.age,
    //   sortOrder: sortedInfo.columnKey === "age" ? sortedInfo.order : null,
    //   ellipsis: true
    // },
    {
      dataIndex: "createdAt",
      key: "createdAt",
      showSorterTooltip: false,
      sorter: (a, b) => +dayjs(a?.createdAt) - +dayjs(b?.createdAt),
      sortOrder: sortedInfo.columnKey === "createdAt" ? sortedInfo.order : null,
      // render: (createdAt) => <>{dayjs(createdAt).format('YYYY-MM-DD HH:mm')}</>,
      render: (createdAt) => <></>,
      width: "0",
    },
    {
      key: "memberCount",
      showSorterTooltip: false,
      sorter: (a, b) => a?.members?.length - b?.members?.length,
      sortOrder:
        sortedInfo.columnKey === "memberCount" ? sortedInfo.order : null,
      render: (members) => <></>,
      width: "0",
    },
    {
      key: "courseCount",
      showSorterTooltip: false,
      sorter: (a, b) => a?.products?.length - b?.products?.length,
      sortOrder:
        sortedInfo.columnKey === "courseCount" ? sortedInfo.order : null,
      render: (products) => <></>,
      width: "0",
    },
    {
      title: (
        <Title level={3} className={style.titleH3_7}>
          課程
        </Title>
      ),
      dataIndex: "products",
      key: "courseIds",
      showSorterTooltip: false,
      filters: [
        { text: "嬰幼兒 父母必修課", value: "33589" },
        { text: "嬰幼兒 感覺統合啟蒙課程", value: "20307" },
        { text: "兒童 感覺統合訓練課程", value: "100358" },
        { text: "兒童 注意力與執行力訓練課程( 附教具 )", value: "60464" },
        {
          text: "兒童/成人 玩美臉型自信開口 全方位口肌訓練課程( 附教具 )",
          value: "231206",
        },
      ],
      filteredValue: filteredInfo.courseIds || null,
      onFilter: (value, record) => {
        // console.log({value, record})
        if (Array.isArray(record?.products)) {
          const filteredProducts = record?.products?.filter(
            (product) => product?.id === value
          );
          return filteredProducts?.length > 0;
        }
      },
      onFilterDropdownOpenChange: (visible) => {
        changeResetBtnText();
      },
      render: (products) => (
        <div className={style.gbCourseListDiv}>
          {Array.isArray(products) &&
            products?.map((product) => courseList[product?.id])}
        </div>
      ),
      width: "48%",
      ellipsis: true,
    },
    // {
    //   dataIndex: 'memberCount',
    //   sorter: true,
    //   render: (memberCount) => <></>,
    // },
    // {
    //   dataIndex: 'courseId',
    //   sorter: true,
    //   render: (courseId) => <></>,
    // },
    {
      title: (
        <Title level={3} className={style.titleH3_7}>
          折數
        </Title>
      ),
      dataIndex: "discount",
      key: "discount",
      render: (discount) => (
        <Title level={3} className={style.titleH3_8}>
          {discount ? discount * 10 : NaN}折
        </Title>
      ),
      width: "15%",
      ellipsis: true,
    },
    {
      title: (
        <Title level={3} className={style.titleH3_7}>
          成員
        </Title>
      ),
      dataIndex: "members",
      key: "memberCount",
      showSorterTooltip: false,
      render: (members, record) => (
        <>
          {members.map(() => (
            <img
              className={style.gbMemberSvg}
              src="https://imagedelivery.net/-7odu1CEBhoPcuWSdxB8-g/624edcff-ca4d-485d-6957-5461c15af700/public"
              alt="member"
            />
          ))}
          <div className={style.gbTableText}>{record?.teamName}</div>
        </>
      ),
      width: "17%",
      ellipsis: true,
    },
    {
      dataIndex: "addTeam",
      key: "addTeam",
      showSorterTooltip: false,
      render: (addTeam, record) => (
        <AntButton
          className={style.gbAntButtonAddTeam}
          onClick={(e) => {
            e?.preventDefault();
            if (!loginToken) {
              handleShow();
            } else {
              window.open(`https://kidp.ro/${record?.shortUrlCode}`);
            }
          }}
        >
          加入團購
        </AntButton>
      ),
      width: "20%",
    },
  ];
  // 測試用
  // const getRandomuserParams = (params) => ({
  //   results: params.pagination?.pageSize,
  //   page: params.pagination?.current,
  //   ...params,
  // });
  /***** END Ant Table *****/

  /*** 取得登入者資料 ***/
  // const getUserData = async() => {
  //   const decoded = jwt_decode(loginToken);
  //   // // // // console.log(decoded);
  //   const uid = decoded?.id || "";
  //   const email = decoded?.email || "";
  //   const atIndex = email.indexOf('@');
  //   const adminUsername = atIndex !== -1 ? email.substring(0, atIndex) : "";
  //   // // // // // console.log(adminUsername);
  //   // setUserId(uid);
  //   return uid;
  // }
  // useEffect(() => {
  //   getUserData();
  // }, []);
  // useEffect(() => {
  //   getUserData();
  // }, [loginToken]);
  /*** END 取得使用者資料 ***/

  /*** GroupBuy頁面狀態 ***/
  const [gbPage, setGbPage] = useState("ListTeamsPage");

  useEffect(() => {
    // // console.log('Sheet: ', Sheet, '!! loginToken', !! loginToken, 'LIFF_STOREregister', LIFF_STOREregister, 'userNormalLoggedInStorage: ', userNormalLoggedInStorage, 'email: ',  email );
    // // console.log(! loginToken && LIFF_STOREregister==="true" && userNormalLoggedInStorage==="false" && email===null);
  }, [Sheet, loginToken, email, userNormalLoggedInStorage, LIFF_STOREregister]);

  /*** Login Popup相關 ***/
  const [show, setShow] = useState(false); // 設定Modal 顯示與否
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const Popup = (props) => {
    const { NOuaabindLine, UserData, setNOuaabindLine, LoginToken } = props;
    return (
      <>
        <>
          <Modal
            show={show}
            onHide={handleClose}
            backdrop="static"
            keyboard={false}
            centered
          >
            <Col className={popupStyle.modal}>
              <div
                className={popupStyle.modalClose}
                onClick={(event) => {
                  handleClose();
                  clearStorage(event);
                }}
              >
                x
              </div>
              <div className={popupStyle.modalHeader}>
                <Modal.Title className={popupStyle.modalTitle}></Modal.Title>
                <div className={popupStyle.PopupBanner}>
                  <img
                    src={
                      "https://imagedelivery.net/-7odu1CEBhoPcuWSdxB8-g/307b61fa-e830-473f-55f4-c2446e462c00/public"
                    }
                  ></img>
                </div>
              </div>
              <Modal.Body className={popupStyle.modalBody}>
                <LoginParent
                  className={popupStyle.modalLoginParent}
                  NOuaabindLine={NOuaabindLine}
                  loginTokenState={loginTokenState}
                  Loginparent={Loginparent}
                  UserData={email}
                  setNOuaabindLine={setNOuaabindLine}
                  LoginToken={LoginToken}
                ></LoginParent>
              </Modal.Body>
            </Col>
          </Modal>
        </>
      </>
    );
  }; /*** END Login Popup相關 ***/

  // /*** 未登入則彈出登入視窗 ***/
  // useEffect(() => {
  //   // console.log('loginToken', loginToken)
  //   handleShow();
  //   if( ! loginToken ) {
  //     handleShow();
  //   } else {
  //     handleClose();
  //   }
  // }, []);

  /*** UTM Events ***/
  const [utm, setUtm] = useState({});
  const [utmKidPro, setUtmKidPro] = useState({});
  const [utmPageViewEventId, setUtmPageViewEventId] = useState("");
  const [utmAddToCartEventId, setUtmAddToCartventId] = useState("");
  const [utmCheckoutEventId, setUtmCheckoutEventId] = useState("");
  const [utmPurchaseEventId, setUtmPurchaseEventId] = useState("");
  const configUTM = async () => {
    // // console.log('configUTM')
    try {
      // const pixelId = process.env.REACT_APP_FB_PIXEL_ID ?? "";
      const gaId = process.env.REACT_APP_GA4_ID ?? "";
      // const pixelIdKidpro = process.env.REACT_APP_FB_PIXEL_ID_KIDPRO ?? "";
      const pixelIdKidpro2 = process.env.REACT_APP_FB_PIXEL_ID_KIDPRO2 ?? "";
      const gaIdKidpro = process.env.REACT_APP_GA4_ID_KIDPRO ?? "";
      // const Utm = await UtmConfig(pixelId, gaId);
      // // console.log('Utm:', Utm);

      // const UtmKidPro = await UtmConfig(pixelIdKidpro, gaIdKidpro);
      // // console.log('UtmKidPro:', UtmKidPro);
      const UtmKidPro2 = await UtmConfig(pixelIdKidpro2, gaIdKidpro);

      // setUtm(Utm);
      // setUtmKidPro(UtmKidPro);
      setUtmKidPro(UtmKidPro2);

      // return {UtmKidPro};
      return {
        // Utm, UtmKidPro,
        UtmKidPro2,
      };
      // 在这里可以继续处理其他逻辑
    } catch (error) {
      throw new Error(error);
    }
    //   // // console.log('Utm', Utm);
    //   // // console.log('UtmKidPro', UtmKidPro);
  };
  useEffect(() => {
    configUTM();
  }, []);
  // useEffect(() => {
  //   // // console.log('Utm', Utm);
  //   // // console.log('UtmKidPro', UtmKidPro);
  // }, [Utm, UtmKidPro]);
  const [contentName, setContentName] =
    useState("三人成團購課－未成團列表頁面");
  const [contentCategory, setContentCategory] = useState(
    "kidpro_marketing_groupbuy"
  );
  const [itemIds, setItemIds] = useState([]); // product id array
  const [items, setItems] = useState([]); // product object array
  const [currency, setCurrency] = useState("TWD");
  const [value, setValue] = useState(NaN);
  const [utmOrderNumber, setUtmOrderNumber] = useState(NaN);
  const [shareMethod, setShareMethod] = useState("");
  UtmEvents(
    contentName,
    contentCategory,
    itemIds,
    items,
    currency,
    value,
    utmOrderNumber,
    shareMethod
  ); // UTM事件全域參數設定
  const utmPageViewAsync = async () => {
    //  utmPageView(Utm, `瀏覽【${contentName}】`); // 瀏覽頁面事件送出
    //  utmPageView(UtmKidPro, eventID, `瀏覽【${contentName}】`); // 瀏覽頁面事件送出
    const {
      // Utm, UtmKidPro,
      UtmKidPro2,
    } = await configUTM();
    // // console.log({Utm, UtmKidPro});
    // await utmPageView(Utm, `瀏覽【${contentName}】`); // 瀏覽頁面事件送出
    const eventID = await conversionApiViewPage();
    // await utmPageView(Utm, eventID, `瀏覽【${contentName}】`); // 瀏覽頁面事件送出
    // await utmPageView(UtmKidPro, eventID, `瀏覽【${contentName}】`); // 瀏覽頁面事件送出
    await utmPageView(UtmKidPro2, eventID, `瀏覽【${contentName}】`); // 瀏覽頁面事件送出
  };
  useEffect(() => {
    utmPageViewAsync();
  }, []);
  /*** END UTM Events ***/

  /*** Conversion API ***/
  const conversionApiViewPage = async () => {
    let BearerToken = undefined;
    if (loginToken) {
      BearerToken = "Bearer " + loginToken;
    }
    try {
      const response = await fetch(
        "https://" +
          microservApiURL +
          "/view-content?sourceUrl=" +
          encodeURI(window.location.href),
        {
          method: "GET",
          credentials: "include",
          headers: {
            Authorization: BearerToken,
            Accept: "application/json",
            "Content-Type": "application/json",
          },
        }
      );
      if (!response.ok) {
        throw new Error("Request failed: conversionApiViewPage()");
      }
      const result = await response.json();
      // console.log(result)
      if (result.success) {
        const eventID = result?.trackerData?.eventId;
        return eventID;
      } else {
        throw new Error("Request failed: conversionApiViewPage()");
        // return '';
      }
    } catch (error) {
      console.log("ConversionApiViewPage Error");
      // throw new Error('ConversionApiViewPage Error: ', error);
      // return '';
    }
  };
  // const conversionApiViewPage = async() => {
  //   let BearerToken = undefined;
  //   if( loginToken ) {
  //     BearerToken = 'Bearer ' + loginToken;
  //   }
  //   await fetch('https://' + microservApiURL + '/view-content?sourceUrl=' + encodeURI(window.location.href), {
  //     method: 'GET',
  //     credentials: "include",
  //     headers: {
  //       'Authorization': BearerToken,
  //       'Accept': 'application/json',
  //       'Content-Type': 'application/json'
  //     }
  //   })
  //   .then((r) => r.json())
  //   .then((result) => {
  //     if(result.success) {
  //       // // console.log('ConversionApiViewPage Success.');
  //       return result?.trackerData?.eventId
  //     } else {
  //       // // console.log('ConversionApiViewPage Failure.', result);
  //       return ''
  //     }
  //   })
  //   .catch((error) => {
  //     // // console.log('ConversionApiViewPage Error');
  //     return ''
  //   });
  // }
  // useEffect(()=>{
  //   conversionApiViewPage();
  // },[]);
  /*** END Conversion API ***/

  /* 返回上一頁清除使用者資料 */
  const previousPageClearStorage = () => {
    // window.addEventListener("popstate", function (e) {
    window.localStorage.removeItem("username");
    window.localStorage.removeItem("smsVeriStatus");
    window.localStorage.removeItem("phoneNumber");
    window.localStorage.removeItem("url");
    // }, false);
  };

  /* 案X清除Local Storage並登出LINE、FB */
  const clearStorage = (event) => {
    event.preventDefault();
    // 如果LINE有登入，執行LINE登出
    const isLoggedIn = liff.isLoggedIn();
    if (isLoggedIn) {
      liff.logout();
    }
    // 如果FB物件存在，且為登入中，將FB登出
    if (!!window.FB) {
      window.FB.getLoginStatus(function (response) {
        if (response.status === "connected") {
          window.FB.logout(function (response) {
            // // // // // // // console.log('handleFBLogout', response);
          });
        }
      });
    }
    // localStorage.clear(); // 20230802 註解此段，避免自動登出
    setNOuaabindLine(false);
    setEmail(null);
    // setTimeout(()=>{
    //   setSheet('LoginIndex');
    // }, 3000);
  };

  //設定loading 轉圈圈
  const [isLoading, setIsLoading] = useState(false);

  const copyText = () => {
    let copy =
      "#KidPro醫學級兒童發展教育團隊_恬兒職能治療師\n#輸入底下優惠碼享購課94折\n【#KidPro科學育兒在你身邊】";
    // let copy = '@恬兒職能治療師-醫學領域的兒童發展專家\n#KidPro優惠碼【科學育兒】購買線上課程享94折';
    navigator.clipboard
      .writeText(copy)
      .then(() => {
        //複製完成顯示checkMark
        setShowCheckMark(true);
        //複製完成三秒後checkMark消失
        setTimeout(() => {
          setShowCheckMark(false);
        }, 3000);
      })
      .catch(() => {
        setShowCheckMark(false);
      });
  };

  const collapseToggle = (e, idx) => {
    e?.preventDefault();
    const panelI = document?.getElementsByClassName(`coursePanel-${idx}`)[0];
    if (!!panelI && panelI.style.display === "block") {
      panelI.style.display = "none";
    } else if (!!panelI) {
      panelI.style.display = "block";
    }
  };
  /*** 20230802之後新增的版本 只要localStorage中的userNormalLoggedInStorage為true，彈出LINE登入視窗 ***/
  /** 目標：
   * v1. 開始進入畫面，不彈出視窗(loginToken == null、Sheet == 'LoginIndex'、LIFF_STOREregister==null、userNormalLoggedInStorage==null、email==null)
   * v2. 未綁定一般登入後，顯示LINE註冊/登入視窗(loginToken == null、Sheet == 'register'、LIFF_STOREregister==null、userNormalLoggedInStorage=="true"、email==null)
   * 3. 未綁定一般登入，LINE註冊/登入點擊後(loginToken == null、Sheet == 'register'、LIFF_STOREregister==null、userNormalLoggedInStorage=="false"、email==null)
   *  v3-1. 未綁定一般登入，LINE註冊/登入點擊後，直接綁定並登入，關閉視窗(loginToken == "字串"、Sheet == 'register'、LIFF_STOREregister=="true"、userNormalLoggedInStorage=="false"、email==null)
   *  // v3-2. 已綁定一般登入，LINE註冊/登入點擊後，LINE Login Token直接登入，關閉視窗(loginToken == "字串"、Sheet == 'register'、LIFF_STOREregister=="true"、userNormalLoggedInStorage=="false"、email=="字串")
   * v4. 未綁定，一開始就點LINE註冊(loginToken == null、Sheet == 'register'、LIFF_STOREregister=="true"、userNormalLoggedInStorage==null、email==null)
   *  v4-1. 未綁定，一開始就點LINE註冊，LINE Login Token綁定舊帳號(用Wordpress登入)，關閉視窗(loginToken == null、Sheet == 'NouaaLineBind'、LIFF_STOREregister=="true"、userNormalLoggedInStorage==null、email==null)
   *    v4-1-1. 登入成功，關閉視窗，同4-3.
   *  v4-2. 未綁定，一開始就點LINE註冊，LINE Login Token註冊Wordpress新帳號(loginToken == null、Sheet == 'NouaaLineBind'、LIFF_STOREregister=="null"、userNormalLoggedInStorage==null、email==null)
   *    v4-2-1. 註冊成功，要求進入登入畫面(此處setSheet由RegisterEmail控制)(loginToken == null、Sheet == 'registerSuccess'、LIFF_STOREregister=="true"、userNormalLoggedInStorage==null、email==null)
   *    v4-2-2. 登入成功，關閉視窗.(loginToken == "字串"、Sheet == 'registerSuccess'、LIFF_STOREregister=="true"、userNormalLoggedInStorage==null、email==null)
   *  v4-3. 已綁定，一開始就點LINE註冊，LINE Login Token直接登入(loginToken == "字串"、Sheet == 'register'、LIFF_STOREregister=="true"、userNormalLoggedInStorage=="false"、email==null)
   * ov5. 已綁定，一般登入後，直接儲存WP Login Token登入，關閉視窗(loginToken == "字串"、Sheet == 'LoginIndex'、LIFF_STOREregister==null、userNormalLoggedInStorage=="true"、email==null)
   * v6. 未綁定直接點選LINE登入，直接登入，關閉視窗，同4-1. 4-2.
   * v7. 已綁定直接點選LINE登入，直接登入，關閉視窗，同4-3. 4-1-1.
   * 8. 其他狀況，回login index，預設關閉視窗
   */
  /*** userNormalLoggedInStorage false => LINE登入成功(不彈出) ***/
  /*** userNormalLoggedInStorage 不存在 => 一般登入(不彈出) ***/
  /*** userNormalLoggedInStorage true => LINE登入(彈出) ***/
  const popupLineLogin = () => {
    // // console.log('loginToken', loginToken)
    // // console.log('LIFF_STOREregister', LIFF_STOREregister)
    // // console.log('userNormalLoggedInStorage', userNormalLoggedInStorage)
    // // console.log('email', email)
    // v1. 4.
    if (
      !loginToken &&
      !LIFF_STOREregister &&
      !userNormalLoggedInStorage &&
      email === null
    ) {
      // // // console.log("1. 4.");
      setTimeout(() => {
        handleShow(); // 特殊頁面，需要讓使用者未登入時，彈出登入視窗讓其登入
      }, 2000);
      // setSheet('LoginIndex'); 1.
      // setSheet('register'); 4.
      // v2.
    } else if (
      !loginToken &&
      !LIFF_STOREregister &&
      userNormalLoggedInStorage === "true" &&
      email === null
    ) {
      // // // console.log("2.");
      handleShow();
      // setSheet('register');
      // v3-1. 4.3
    } else if (
      !!loginToken &&
      LIFF_STOREregister === "true" &&
      userNormalLoggedInStorage === "false" &&
      email === null
    ) {
      // // // console.log("3-1.");
      handleClose();
      // setSheet('register');
      // v3-2.
    } else if (
      !!loginToken &&
      LIFF_STOREregister === "true" &&
      userNormalLoggedInStorage === "true" &&
      email === null
    ) {
      // console.log("3-2. 5.");
      handleClose();
      // setSheet('LoginIndex');
      // 4-1. 4-2. 6
    } else if (
      !loginToken &&
      LIFF_STOREregister === "true" &&
      !userNormalLoggedInStorage &&
      email === null
    ) {
      // // // console.log("4-1. 4-2. 6");
      handleShow();
      // setTimeout(()=>{
      //   setSheet('NOuaabindLine');
      // }, 3000);
      // setSheet('NOuaabindLine');
      // 4-1-1. 4-2-2.
    } else if (
      !!loginToken &&
      LIFF_STOREregister === "true" &&
      !userNormalLoggedInStorage &&
      email === null
    ) {
      // // // console.log("4-3. 4-1-1.");
      handleClose();
      // setSheet('register');
      // 5. (true null true null)
    } else {
      // // // console.log("else");
      // window.localStorage.clear();
      // setEmail(null);
      handleClose();
    }

    // if( userNormalLoggedInStorage !== "false" ) { // 一般登入前、一般登入後LINE登入前
    //   handleShow();
    // } else if( userNormalLoggedInStorage !== 'true' || loginToken ) { // 一般登入前、一般登入後LINE登入前
    //   handleShow();
    // } else {
    //   handleClose();
    // }
  };

  useEffect(() => {
    previousPageClearStorage(); // 回上一頁清除關於個資的local storage
    popupLineLogin(); // 20230802之後新增 彈出LINE註冊/登入視窗
  }, []);

  return (
    <>
      <Container fluid className={style.container_fluid}>
        <Row className={style.gbContainerRow}>
          <Col className={style.container_customMargin}>
            <Col className={style.bannerColPhone}>
              <Layout className={style.layoutPhone}>
                <img
                  style={{ width: "100%" }}
                  src="https://imagedelivery.net/-7odu1CEBhoPcuWSdxB8-g/ba59ffa8-0fa1-4c10-eb84-8bb18f2c1200/public"
                ></img>
              </Layout>
            </Col>
            <Col className={style.bannerColPC}>
              <div className={style.bannerSec}>
                <div className={style.bannerTitleImgDiv}>
                  <Layout className={style.layoutComp}>
                    <img
                      style={{ width: "100%", paddingBottom: 20 }}
                      src="https://imagedelivery.net/-7odu1CEBhoPcuWSdxB8-g/3454a25f-ae64-4291-8c0c-5133f479e300/public"
                    ></img>
                  </Layout>
                </div>
              </div>
            </Col>
            <Col className={style.gbMain}>
              <Col className={style.memberTableCol}>
                <div className={style.memberDescriptDiv}>
                  <Title level={3} className={style.titleH3_6}>
                    湊滿3人合購線上課程
                  </Title>
                  <Title level={5} className={style.titleH5}>
                    依團購課程數量 享有不同折扣
                  </Title>
                  <Title level={5} className={style.titleH5}>
                    1堂4折，2堂3.5折，3堂3.3折，4堂3.2折，5堂3折
                  </Title>
                </div>
              </Col>
              <Row className={style.gbTeamsList}>
                <Space className={style.gbTableSortBtnSpace}>
                  <Button
                    className={style.gbTableSortBtn}
                    onClick={setTimeSort}
                  >
                    開團時間
                    {timeOrder === "ASC" ? (
                      <CaretUpOutlined style={{ marginLeft: "4px" }} />
                    ) : (
                      <CaretDownOutlined style={{ marginLeft: "4px" }} />
                    )}
                  </Button>
                  <Button
                    className={style.gbTableSortBtn}
                    onClick={setMemberCountSort}
                  >
                    成員數
                    {memberOrder === "ASC" ? (
                      <CaretUpOutlined style={{ marginLeft: "4px" }} />
                    ) : (
                      <CaretDownOutlined style={{ marginLeft: "4px" }} />
                    )}
                  </Button>
                  {/* <Button className={style.gbTableSortBtn} onClick={setCourseCountSort}>課程數{courseOrder==="ASC"?(<CaretUpOutlined style={{marginLeft: '4px'}}/>):(<CaretDownOutlined style={{marginLeft: '4px'}}/>)}</Button> */}
                  {/* <Button className={style.gbTableSortBtn} onClick={filterCourse}>課程篩選</Button> */}
                </Space>
                <Table
                  id="antTable"
                  // onHeaderCell={(columns) => {
                  //   console.log('onHeaderCell')
                  //   return {
                  //     onClick: () => {
                  //       console.log('onHeaderCell')
                  //     }, // 点击表头行
                  //   };
                  // }}
                  // onHeaderRow={(columns, index) => {
                  //   console.log('onHeaderCell')
                  //   return {
                  //     onClick: () => {
                  //       console.log('onHeaderCell')
                  //     }, // 点击表头行
                  //   };
                  // }}
                  // filterDropdownOpen={true}
                  columns={columns}
                  rowKey={(record) => record._id} // record: data array中的其中一個object element
                  dataSource={data}
                  pagination={tableParams.pagination}
                  loading={loading}
                  onChange={handleTableChange}
                />
                <div className={style.gbErrMsgDiv}>
                  <span className={style.gbErrMsg}>{RegxpError}</span>
                </div>
              </Row>
            </Col>
            <Row className={style.gbBottomRow0}>
              <Col className={style.gbBottomRow0Col}></Col>
            </Row>
            <Row className={`${style.gbRowAntButton}`}>
              <AntButton
                className={style.gbAntButton}
                onClick={(e) => {
                  e?.preventDefault();
                  if (!loginToken) {
                    handleShow();
                  } else {
                    window.location.href =
                      "https://" + window.location.host + "/groupBuy";
                  }
                }}
              >
                揪新團＋
              </AntButton>
            </Row>
            <Row className={`${style.gbRowNote}`}>
              <ul className={style.gbUlNote}>
                <li className={`${style.gbLiNote}`}>
                  本活動在組團時只會進行授權，並不會請款。您的卡號將會安全的保存在第三方刷卡公司，只有在成團時才會請款並且正式完成訂單，完成訂單後您馬上就可以觀看課程了！
                </li>
                <li className={`${style.gbLiNote}`}>
                  由於您無法重複購買同一門課程，當您加入多個團購，其中一團成團時，其他未成單的團當中，若和已成的團有重複的課程，這些未成的團，都會自動退出，您不用擔心會重複購課。
                </li>
              </ul>
            </Row>
            <div style={{ padding: "24px" }}></div>
          </Col>
        </Row>
      </Container>
      <Popup NOuaabindLine={NOuaabindLine} LoginToken={LoginToken}></Popup>
      <Footer></Footer>
    </>
  );
}

export default GroupBuyListTeams;
