import React from 'react';

const authContext = React.createContext({
  //使用authorized 判定是否為登入狀態
  authorized: false,
  email: null,
  userNormalLoggedIn: null,
  addFriendStatus: null
});

export default authContext;